#skills {
  background-color: #f8fafc;
  position: relative;
  overflow: hidden;
}

#skills::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 10% 90%, rgba(59, 130, 246, 0.05), transparent 30%),
              radial-gradient(circle at 90% 10%, rgba(16, 185, 129, 0.05), transparent 30%);
  z-index: 0;
}

.skills-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
  position: relative;
  z-index: 1;
}

.skills-category {
  background-color: white;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.skills-category:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
}

.category-title {
  font-size: 1.5rem;
  color: var(--primary);
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  font-weight: 700;
}

.category-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: linear-gradient(135deg, var(--secondary), rgba(59, 130, 246, 0.7));
  border-radius: 10px;
  margin-right: 1rem;
  color: white;
}

.skills-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

.skill-item {
  width: 100%;
}

.skill-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.skill-name {
  font-weight: 600;
  color: var(--primary);
}

.skill-percentage {
  color: var(--secondary);
  font-weight: 500;
}

.skill-level {
  height: 10px;
  background-color: rgba(59, 130, 246, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.skill-level-fill {
  height: 100%;
  background: linear-gradient(90deg, var(--secondary), var(--tertiary));
  border-radius: 10px;
  width: 0;
  transition: width 1.5s ease;
}

/* Add different gradient colors for each category */
.skills-category:nth-child(1) .skill-level-fill {
  background: linear-gradient(90deg, #3b82f6, #10b981);
}

.skills-category:nth-child(2) .skill-level-fill {
  background: linear-gradient(90deg, #8b5cf6, #ec4899);
}

.skills-category:nth-child(3) .skill-level-fill {
  background: linear-gradient(90deg, #f59e0b, #ef4444);
}

@media (min-width: 768px) {
  .skills-container {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .skills-category:last-child {
    grid-column: span 2;
  }
}

@media (min-width: 1024px) {
  .skills-container {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .skills-category:last-child {
    grid-column: auto;
  }
  
  .skills-grid {
    gap: 1.25rem;
  }
}