.hero {
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding: 0;
    position: relative;
    overflow: hidden;
  }
  
  .hero::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 80% 10%, rgba(59, 130, 246, 0.15), transparent 40%),
                radial-gradient(circle at 20% 90%, rgba(16, 185, 129, 0.1), transparent 40%);
    z-index: -1;
  }
  
  .shapes {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.4;
  }
  
  .shape {
    position: absolute;
    border-radius: 50%;
  }
  
  .shape-1 {
    background: linear-gradient(45deg, var(--secondary), #6366f1);
    width: 300px;
    height: 300px;
    top: -150px;
    right: -100px;
    filter: blur(90px);
  }
  
  .shape-2 {
    background: linear-gradient(45deg, var(--tertiary), #0ea5e9);
    width: 400px;
    height: 400px;
    bottom: -200px;
    left: -200px;
    filter: blur(120px);
  }
  
  .hero-content {
    max-width: 800px;
  }
  
  .subtitle {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--secondary);
    margin-bottom: 1rem;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeUp 0.8s ease forwards 0.3s;
  }
  
  .title {
    font-size: 4.5rem;
    font-weight: 800;
    color: var(--primary);
    line-height: 1.1;
    margin-bottom: 1.5rem;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeUp 0.8s ease forwards 0.5s;
  }
  
  .highlight {
    position: relative;
    display: inline-block;
    z-index: 1;
  }
  
  .highlight::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 10px;
    width: 100%;
    height: 12px;
    background-color: rgba(16, 185, 129, 0.2);
    z-index: -1;
    transform: scaleX(0);
    transform-origin: left;
    animation: highlightIn 0.8s ease forwards 1.2s;
  }
  
  .description {
    font-size: 1.25rem;
    line-height: 1.7;
    margin-bottom: 2.5rem;
    max-width: 600px;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeUp 0.8s ease forwards 0.7s;
  }
  
  .cta-wrapper {
    display: flex;
    gap: 1.5rem;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeUp 0.8s ease forwards 0.9s;
  }
  
  .cta-primary {
    background-color: var(--secondary);
    color: white;
    padding: 0.75rem 2rem;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1rem;
    text-decoration: none;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  
  .cta-primary:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 25px -5px rgba(59, 130, 246, 0.4);
  }
  
  .cta-primary::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: linear-gradient(90deg, rgba(255,255,255,0.1), rgba(255,255,255,0));
    transition: width 0.5s ease;
    z-index: -1;
  }
  
  .cta-primary:hover::before {
    width: 100%;
  }
  
  .cta-secondary {
    color: var(--primary);
    padding: 0.75rem 2rem;
    border-radius: 8px;
    border: 2px solid rgba(15, 23, 42, 0.1);
    font-weight: 600;
    font-size: 1rem;
    text-decoration: none;
    transition: all 0.3s ease;
    background-color: transparent;
  }
  
  .cta-secondary:hover {
    border-color: var(--secondary);
    background-color: rgba(59, 130, 246, 0.05);
  }
  
  .scroll-indicator {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    animation: fadeIn 0.8s ease forwards 1.5s, bounce 2s ease-in-out infinite 2s;
  }
  
  .scroll-indicator svg {
    width: 30px;
    height: 30px;
    fill: none;
    stroke: var(--text);
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  
  .tech-stack {
    position: absolute;
    bottom: 40px;
    right: 10%;
    display: flex;
    gap: 1.5rem;
    opacity: 0;
    animation: fadeIn 0.8s ease forwards 1.3s;
  }
  
  .tech-item {
    background-color: white;
    padding: 0.5rem 1rem;
    border-radius: 30px;
    font-size: 0.8rem;
    font-weight: 500;
    color: var(--primary);
    opacity: 0.6;
    transition: all 0.3s ease;
  }
  
  .tech-item:hover {
    opacity: 1;
    transform: translateY(-5px);
    color: var(--secondary);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  /* Typing animation for role */
  .typing-container {
    display: inline-block;
    position: relative;
    margin-bottom: 1.5rem;
  }
  
  .typing-text {
    position: relative;
    color: var(--tertiary);
    font-weight: 700;
    font-size: 1.5rem;
  }
  
  .typing-text::after {
    content: '|';
    position: absolute;
    right: -10px;
    color: var(--secondary);
    animation: blink 1s step-end infinite;
  }
  
  @keyframes blink {
    from, to {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  
  @keyframes fadeUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  
  @keyframes highlightIn {
    to {
      transform: scaleX(1);
    }
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateX(-50%) translateY(0);
    }
    50% {
      transform: translateX(-50%) translateY(10px);
    }
  }
  
  @media (max-width: 768px) {
    .hero {
      padding: 0 5%;
    }
    
    .title {
      font-size: 3rem;
    }
    
    .description {
      font-size: 1.1rem;
    }
    
    .tech-stack {
      display: none;
    }
    
    .cta-wrapper {
      flex-direction: column;
      gap: 1rem;
    }
  }