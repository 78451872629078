#projects {
    background-color: #f8fafc;
  }
  
  .projects-filter {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 3rem;
  }
  
  .filter-button {
    padding: 0.5rem 1rem;
    background-color: white;
    border: 2px solid rgba(15, 23, 42, 0.1);
    border-radius: 30px;
    font-size: 0.9rem;
    font-weight: 500;
    color: var(--text);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .filter-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    color: var(--secondary);
    border-color: var(--secondary);
  }
  
  .filter-button.active {
    background-color: var(--secondary);
    color: white;
    border-color: var(--secondary);
  }
  
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 2.5rem;
  }
  
  .project-card {
    background-color: white;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
  }
  
  .project-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  }
  
  .project-image {
    position: relative;
    height: 220px;
    overflow: hidden;
  }
  
  .project-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease;
  }
  
  .project-card:hover .project-image img {
    transform: scale(1.05);
  }
  
  .project-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(15, 23, 42, 0.8), transparent);
    opacity: 0;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .project-card:hover .project-overlay {
    opacity: 1;
  }
  
  .project-links {
    display: flex;
    gap: 1rem;
    transform: translateY(20px);
    transition: all 0.3s ease 0.1s;
  }
  
  .project-card:hover .project-links {
    transform: translateY(0);
  }
  
  .project-link {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    text-decoration: none;
    transition: all 0.3s ease;
  }
  
  .project-link:hover {
    background-color: var(--secondary);
    color: white;
    transform: translateY(-3px);
  }
  
  .project-content {
    padding: 1.5rem;
  }
  
  .project-title {
    font-size: 1.3rem;
    font-weight: 700;
    color: var(--primary);
    margin-bottom: 1rem;
  }
  
  .project-description {
    font-size: 0.9rem;
    line-height: 1.6;
    color: var(--text);
    margin-bottom: 1.5rem;
  }
  
  .project-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  
  .project-tag {
    padding: 0.25rem 0.75rem;
    background-color: rgba(59, 130, 246, 0.1);
    color: var(--secondary);
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 500;
  }
  
  @media (max-width: 768px) {
    .projects-grid {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
    
    .project-image {
      height: 200px;
    }
    
    .filter-button {
      font-size: 0.8rem;
      padding: 0.4rem 0.8rem;
    }
    
    .projects-filter {
      gap: 0.5rem;
      margin-bottom: 2rem;
    }
  }