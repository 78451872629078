.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(248, 250, 252, 0.9);
    backdrop-filter: blur(10px);
    z-index: 1000;
    transition: all 0.3s ease;
    padding: 15px 0;
  }
  
  .header.scrolled {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    padding: 10px 0;
  }
  
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--primary);
    text-decoration: none;
  }
  
  .logo span {
    color: var(--secondary);
  }
  
  .nav-menu {
    display: flex;
    list-style: none;
    gap: 2rem;
  }
  
  .nav-link {
    color: var(--primary);
    text-decoration: none;
    font-weight: 500;
    position: relative;
    transition: all 0.3s ease;
  }
  
  .nav-link::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--secondary);
    transition: all 0.3s ease;
  }
  
  .nav-link:hover {
    color: var(--secondary);
  }
  
  .nav-link:hover::after {
    width: 100%;
  }
  
  .nav-link.active {
    color: var(--secondary);
    font-weight: 600;
  }
  
  .nav-link.active::after {
    width: 100%;
  }
  
  .mobile-menu-btn {
    display: none;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    color: var(--primary);
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .nav-menu {
      position: fixed;
      top: 70px;
      left: -100%;
      flex-direction: column;
      background-color: var(--light);
      width: 100%;
      border-radius: 0 0 10px 10px;
      text-align: center;
      transition: all 0.3s ease;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
      padding: 20px 0;
      gap: 1.5rem;
    }
    
    .nav-menu.active {
      left: 0;
    }
    
    .mobile-menu-btn {
      display: block;
    }
  }