/* Base Variables and Reset */
:root {
  --primary: #0f172a;
  --secondary: #3b82f6;
  --tertiary: #10b981;
  --light: #f8fafc;
  --dark: #1e293b;
  --text: #475569;
  --error: #ef4444;
  --success: #22c55e;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
}

body {
  background-color: var(--light);
  color: var(--text);
  overflow-x: hidden;
}

section {
  padding: 100px 0;
  position: relative;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.section-title {
  font-size: 2.5rem;
  color: var(--primary);
  text-align: center;
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

.section-title::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  width: 80px;
  height: 4px;
  background: linear-gradient(90deg, var(--secondary), var(--tertiary));
  border-radius: 2px;
}

.section-subtitle {
  text-align: center;
  font-size: 1.2rem;
  max-width: 700px;
  margin: 0 auto 4rem;
}

.center-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
}

/* Floating elements for design */
.floating-element {
  position: absolute;
  opacity: 0.07;
  z-index: 0;
}

.circle {
  border-radius: 50%;
}

.square {
  border-radius: 8px;
}

.triangle {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

/* Responsive styles */
@media (max-width: 768px) {
  section {
    padding: 60px 0;
  }
  
  .section-title {
    font-size: 2rem;
  }
  
  .section-subtitle {
    font-size: 1rem;
  }
}