.footer {
    background-color: var(--primary);
    color: white;
    padding: 3rem 0 1.5rem;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .footer-logo {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    color: white;
  }
  
  .footer-logo span {
    color: var(--secondary);
  }
  
  .footer-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .footer-nav-link {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    font-size: 0.9rem;
    transition: all 0.3s ease;
  }
  
  .footer-nav-link:hover {
    color: white;
  }
  
  .footer-social {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .footer-social-link {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  
  .footer-social-link:hover {
    background-color: var(--secondary);
    transform: translateY(-3px);
  }
  
  .footer-social-link svg {
    width: 18px;
    height: 18px;
  }
  
  .footer-text {
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.6);
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }
  
  .copyright {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.4);
  }
  
  .heart-icon {
    color: #f43f5e;
  }
  
  @media (max-width: 768px) {
    .footer-nav {
      flex-direction: column;
      gap: 1rem;
    }
  }