/* Timeline styling */
.timeline-container {
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .timeline-center-line {
    position: absolute;
    width: 4px;
    background-color: rgba(59, 130, 246, 0.2);
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
  }
  
  .timeline-item {
    position: relative;
    margin-bottom: 60px;
    clear: both;
    overflow: hidden;
  }
  
  .timeline-item:last-child {
    margin-bottom: 0;
  }
  
  .timeline-content {
    position: relative;
    width: calc(50% - 40px);
    background-color: white;
    border-radius: 16px;
    padding: 25px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    z-index: 1;
  }
  
  .timeline-content:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
  }
  
  .timeline-item:nth-child(odd) .timeline-content {
    float: right;
  }
  
  .timeline-item:nth-child(even) .timeline-content {
    float: left;
  }
  
  .timeline-dot {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: var(--secondary);
    border-radius: 50%;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    transition: all 0.3s ease;
  }
  
  .timeline-item:hover .timeline-dot {
    background-color: var(--tertiary);
    box-shadow: 0 0 0 6px rgba(16, 185, 129, 0.2);
  }
  
  .timeline-date {
    position: absolute;
    top: 22px;
    width: 120px;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--primary);
    background-color: white;
    padding: 5px 10px;
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    z-index: 2;
  }
  
  .timeline-item:nth-child(odd) .timeline-date {
    left: calc(50% - 140px);
  }
  
  .timeline-item:nth-child(even) .timeline-date {
    right: calc(50% - 140px);
  }
  
  .timeline-title {
    margin-bottom: 15px;
    font-size: 1.3rem;
    font-weight: 700;
    color: var(--primary);
  }
  
  .timeline-subtitle {
    color: var(--secondary);
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 15px;
  }
  
  .timeline-description {
    font-size: 0.95rem;
    line-height: 1.6;
    color: var(--text);
  }
  
  .timeline-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 15px;
  }
  
  .timeline-tag {
    padding: 5px 10px;
    background-color: rgba(59, 130, 246, 0.1);
    color: var(--secondary);
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 500;
  }
  
  .timeline-item:nth-child(odd) .timeline-content::before {
    content: '';
    position: absolute;
    top: 22px;
    left: -15px;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-right: 15px solid white;
    border-bottom: 15px solid transparent;
  }
  
  .timeline-item:nth-child(even) .timeline-content::before {
    content: '';
    position: absolute;
    top: 22px;
    right: -15px;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 15px solid white;
    border-bottom: 15px solid transparent;
  }
  
  /* Company logo styles */
  .company-logo {
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    z-index: 3;
  }
  
  .timeline-item:nth-child(odd) .company-logo {
    left: calc(50% + 20px);
  }
  
  .timeline-item:nth-child(even) .company-logo {
    right: calc(50% + 20px);
  }
  
  .company-logo  {
    display: none;
  }
  .logo-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: var(--secondary);
    font-size: 1rem;
  }
  
  /* Achievements styles */
  .timeline-achievements {
    margin-top: 15px;
  }
  
  .achievement-item {
    position: relative;
    padding-left: 25px;
    margin-bottom: 8px;
    font-size: 0.9rem;
    line-height: 1.5;
  }
  
  .achievement-item:last-child {
    margin-bottom: 0;
  }
  
  .achievement-item::before {
    content: '✓';
    position: absolute;
    left: 0;
    top: 0;
    color: var(--tertiary);
    font-weight: bold;
  }
  
  .timeline-button {
    display: block;
    margin: 40px auto 0;
    padding: 0.75rem 2rem;
    background-color: transparent;
    border: 2px solid var(--secondary);
    color: var(--secondary);
    border-radius: 8px;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    max-width: 200px;
    text-decoration: none;
  }
  
  .timeline-button:hover {
    background-color: var(--secondary);
    color: white;
  }
  
  /* Clear floats after the timeline */
  .timeline-container::after {
    content: '';
    display: table;
    clear: both;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .timeline-center-line {
      left: 40px;
    }
    
    .timeline-content {
      width: calc(100% - 80px);
      float: right;
      margin-left: 80px;
    }
    
    .timeline-dot {
      left: 40px;
    }
    
    .timeline-item:nth-child(odd) .timeline-content::before,
    .timeline-item:nth-child(even) .timeline-content::before {
      left: -15px;
      right: auto;
      border-top: 15px solid transparent;
      border-right: 15px solid white;
      border-left: 0;
      border-bottom: 15px solid transparent;
    }
    
    .timeline-date {
      width: auto;
      position: relative;
      display: inline-block;
      top: 0;
      margin-bottom: 10px;
      left: 0 !important;
      right: 0 !important;
    }
    
    .company-logo {
      display: none;
    }
    
    .timeline-item:nth-child(odd) .timeline-date,
    .timeline-item:nth-child(even) .timeline-date {
      position: relative;
      left: 0;
      right: 0;
      top: 0;
      margin-bottom: 15px;
    }
  }