.about-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    align-items: center;
    position: relative;
  }
  
  .about-image-container {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    transform-style: preserve-3d;
    perspective: 1000px;
    height: 500px;
  }
  
  .about-image {
    width: 100%;
    height: 100%;
    background-color: #e2e8f0;
    /* Replace the invalid placeholder URL with a gradient background */
    background-image: linear-gradient(45deg, #3b82f6, #10b981);
    background-size: cover;
    background-position: center;
    display: block;
    transform: translateZ(0);
    transition: all 0.3s ease;
  }
  
  .about-image-container:hover .about-image {
    transform: scale(1.05) translateZ(0);
  }
  
  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(15, 23, 42, 0.1) 0%, rgba(15, 23, 42, 0.7) 100%);
    opacity: 0.6;
    transition: all 0.3s ease;
  }
  
  .about-image-container:hover .image-overlay {
    opacity: 0.4;
  }
  
  .about-content {
    position: relative;
    z-index: 1;
  }
  
  .about-header {
    font-size: 2rem;
    color: var(--primary);
    margin-bottom: 1.5rem;
    font-weight: 700;
  }
  
  .about-text {
    font-size: 1.1rem;
    line-height: 1.8;
    margin-bottom: 2rem;
  }
  
  .highlight {
    color: var(--secondary);
    font-weight: 600;
  }
  .about-stats {
    margin-top: 2.5rem;
    margin-bottom: 2rem;
    width: 100%;
  }
  
  .stat-item {
    background-color: white;
    border-radius: 16px;
    padding: 1.5rem;
    text-align: center;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    border: 2px solid rgba(59, 130, 246, 0.1);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .stat-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    border-color: var(--secondary);
  }
  
  .stat-number {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    background: linear-gradient(135deg, var(--secondary), var(--tertiary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
  }
  
  .stat-label {
    font-size: 1.2rem;
    color: var(--primary);
    font-weight: 600;
  }
  
  .personal-info {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
  }
  
  .info-item {
    width: 50%;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .info-icon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: linear-gradient(135deg, var(--secondary), rgba(59, 130, 246, 0.7));
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    color: white;
    flex-shrink: 0;
  }
  
  .info-text {
    font-size: 1rem;
    color: var(--primary);
  }
  
  .info-label {
    font-size: 0.8rem;
    color: var(--text);
    margin-bottom: 0.25rem;
  }
  
  .cta-button {
    display: inline-block;
    padding: 1rem 2rem;
    background: linear-gradient(135deg, var(--secondary), #1d4ed8);
    color: white;
    border-radius: 8px;
    font-weight: 600;
    text-decoration: none;
    margin-top: 1.5rem;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  
  .cta-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background: linear-gradient(135deg, #1d4ed8, var(--secondary));
    transition: all 0.5s ease;
    z-index: -1;
  }
  
  .cta-button:hover {
    box-shadow: 0 10px 20px rgba(59, 130, 246, 0.4);
    transform: translateY(-3px);
  }
  
  .cta-button:hover::before {
    width: 100%;
  }
  
  /* Skills tags */
  .skills-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-top: 1.5rem;
  }
  
  .skill-tag {
    padding: 0.5rem 1rem;
    background-color: rgba(59, 130, 246, 0.1);
    color: var(--secondary);
    border-radius: 30px;
    font-size: 0.9rem;
    font-weight: 500;
    transition: all 0.3s ease;
  }
  
  .skill-tag:hover {
    background-color: var(--secondary);
    color: white;
    transform: translateY(-3px);
  }
  
  @media (max-width: 768px) {
    .about-container {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
    
    .about-image-container {
      max-width: 80%;
      margin: 0 auto;
      height: 400px;
    }
    
    .about-header {
      font-size: 1.75rem;
    }
    
    .about-text {
      font-size: 1rem;
    }
    
    .about-stats {
      grid-template-columns: repeat(2, 1fr);
    }
    
    .info-item {
      width: 100%;
    }
  }