#contact {
    background-color: #f1f5f9;
  }
  
  .contact-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    align-items: center;
  }
  
  .contact-info {
    position: relative;
    z-index: 1;
  }
  
  .contact-header {
    font-size: 2rem;
    color: var(--primary);
    margin-bottom: 1.5rem;
    font-weight: 700;
  }
  
  .contact-description {
    font-size: 1.1rem;
    line-height: 1.7;
    margin-bottom: 2.5rem;
  }
  
  .contact-methods {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .contact-method {
    display: flex;
    align-items: center;
    gap: 1rem;
    transition: all 0.3s ease;
    padding: 1rem;
    border-radius: 12px;
    background-color: white;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  }
  
  .contact-method:hover {
    transform: translateX(10px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.08);
  }
  
  .method-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(135deg, var(--secondary), #1d4ed8);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    flex-shrink: 0;
  }
  
  .method-icon svg {
    width: 24px;
    height: 24px;
  }
  
  .method-content {
    flex: 1;
  }
  
  .method-label {
    font-size: 0.85rem;
    color: var(--text);
    margin-bottom: 0.25rem;
  }
  
  .method-value {
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--primary);
  }
  
  .social-links {
    display: flex;
    gap: 1rem;
    margin-top: 2.5rem;
  }
  
  .social-link {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    text-decoration: none;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
  }
  
  .social-link:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    color: var(--secondary);
  }
  
  .social-link svg {
    width: 20px;
    height: 20px;
  }
  
  .contact-form-container {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
    padding: 2.5rem;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease;
  }
  
  .contact-form-container:hover {
    transform: translateY(-10px);
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.1);
  }
  
  .form-header {
    font-size: 1.5rem;
    color: var(--primary);
    margin-bottom: 2rem;
    font-weight: 700;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
    position: relative;
  }
  
  .form-control {
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    border: 2px solid #e2e8f0;
    border-radius: 10px;
    background-color: #f8fafc;
    transition: all 0.3s ease;
    color: var(--primary);
  }
  
  .form-control:focus {
    border-color: var(--secondary);
    outline: none;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.2);
  }
  
  .form-label {
    position: absolute;
    left: 1rem;
    top: 1rem;
    font-size: 1rem;
    color: var(--text);
    transition: all 0.3s ease;
    pointer-events: none;
  }
  
  .form-control:focus + .form-label,
  .form-control:not(:placeholder-shown) + .form-label {
    top: -0.5rem;
    left: 0.75rem;
    font-size: 0.75rem;
    padding: 0 0.25rem;
    background-color: white;
    color: var(--secondary);
  }
  
  .form-control::placeholder {
    opacity: 0;
  }
  
  textarea.form-control {
    min-height: 120px;
    resize: vertical;
  }
  
  .submit-btn {
    display: block;
    width: 100%;
    padding: 1rem;
    background: linear-gradient(135deg, var(--secondary), #1d4ed8);
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 1rem;
  }
  
  .submit-btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(59, 130, 246, 0.4);
  }
  
  .form-feedback {
    padding: 1rem;
    border-radius: 10px;
    margin-top: 1rem;
    font-size: 0.9rem;
    font-weight: 500;
  }
  
  .error-message {
    background-color: rgba(239, 68, 68, 0.1);
    color: var(--error);
    border: 1px solid rgba(239, 68, 68, 0.2);
  }
  
  .success-message {
    background-color: rgba(34, 197, 94, 0.1);
    color: var(--success);
    border: 1px solid rgba(34, 197, 94, 0.2);
  }
  
  @media (max-width: 768px) {
    .contact-container {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
    
    .contact-header {
      font-size: 1.75rem;
    }
    
    .contact-description {
      font-size: 1rem;
    }
    
    .contact-form-container {
      padding: 2rem;
    }
    
    .form-header {
      font-size: 1.25rem;
    }
  }